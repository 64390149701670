.header--standard.header--standard-landing {
    width: 100%;
    left: auto;
    top: 0;
    position: absolute;
}

.registration-login-form {
    border: 1px solid #e6ecf5;
    border-radius: 0 5px 5px 0;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    padding-left: 0px;
    margin-bottom: 20px;
    min-height: auto;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}

.header--standard {
    padding: 0px 44px;
}

.stunning-header-title {
    color: #FFF;
    font-size: 40px;
}

